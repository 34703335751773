import React from "react";
import * as R from "ramda";
import Header from "components/order-profile/header";
import { FULFILLMENT } from "utils/enum";
import { OrderProfileContext } from "./context";

const SendToFulfillment = () => {
  const { order, updateOrder } = React.useContext(OrderProfileContext);
  const acceptable_fulfillment = order.acceptable_fulfillment ?? [];

  if (order.order_status !== "Accepted") return null;

  return (
    <div className="send-to-fulfillment">
      {acceptable_fulfillment.length === 0 ? null : (
        <>
          <Header>Send to Fulfillment</Header>
          <div>
            {R.innerJoin(
              (f, value) => f.value === value,
              FULFILLMENT,
              acceptable_fulfillment ?? []
            ).map(({ text, value }) => (
              <button
                key={value}
                onClick={() => {
                  updateOrder({ fulfillment_type: value });
                }}
              >
                {text}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SendToFulfillment;
