import React from "react";
import Barcode from "react-barcode";
import { OrderProfileContext } from "./context";

const BarcodeDisplay = () => {
  const { order } = React.useContext(OrderProfileContext);
  const salesOrderNumberByIdString = order.sales_order_number ?? "";
  const salesOrderNumberArray = salesOrderNumberByIdString?.split(",") ?? "";
  const salesOrderNumber = salesOrderNumberArray
    ? salesOrderNumberArray[0]
    : salesOrderNumberByIdString;
  //Sometimes companies put multiple order numbers into this field, so we make an array of order numbers here and map a barcode for each split
  return salesOrderNumber == null || salesOrderNumber.trim() == "" ? (
    <div></div>
  ) : (
    <div>
      <h3>Sales Order Number</h3>
      <Barcode
        value={salesOrderNumber}
        lineColor="#000000"
        width={1}
        height={50}
      />
    </div>
  );
};

export default BarcodeDisplay;
