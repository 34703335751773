import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { InlineWaiting } from "components/ui";
import { OrderProfileContext } from "components/order-profile-new/context";

const AddClipboardItem = ({ orderTaskIds, orderId, canAddTask, patientId }) => {
  const taskActions = useSelector(selectors.getTaskActionsOptions);

  const { createTask, createOrderNoteApi } =
    React.useContext(OrderProfileContext);

  return (
    <Formik
      initialValues={{
        orderId,
        action_id: "",
        note: ""
      }}
      onSubmit={async (values, { resetForm }) => {
        const { orderId, action_id, note } = values;
        if (action_id.length === 0 && note.trim().length == 0) return;
        if (action_id) {
          await createTask({
            orderId,
            action_id,
            text: note,
            patientId,
            type: "Patient"
          });
        } else {
          await createOrderNoteApi.fetchData({
            note
          });
        }
        resetForm();
      }}
      enableReinitialize
    >
      {({ values, isSubmitting }) => (
        <Form className="add-reason-select" style={{ width: "100%" }}>
          <FormikField
            name="action_id"
            component="select"
            placeholder="Worklist Item"
          >
            <option value="">Create Note</option>
            {canAddTask && (
              <React.Fragment>
                <option value="" disabled>
                  --------------- Tasks --------------
                </option>
                {taskActions
                  .filter(
                    ({ value, active, is_order_task }) =>
                      !orderTaskIds.includes(value) && active && is_order_task
                  )
                  .map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
              </React.Fragment>
            )}
          </FormikField>
          <FormikField
            name="note"
            placeholder={values.holdReasonToAdd ? "Comment..." : "Note..."}
            type="text"
          />
          {isSubmitting ? (
            <InlineWaiting />
          ) : (
            <button
              type="submit"
              disabled={
                values.action_id.length === 0 && values.note.trim().length == 0
              }
            >
              {values.action_id ? "Add Task" : "Create Note"}
            </button>
          )}
        </Form>
      )}
    </Formik>
  );
};

AddClipboardItem.defaultProps = {
  orderTaskIds: []
};

AddClipboardItem.propTypes = {
  orderId: PropTypes.string.isRequired,
  canAddTask: PropTypes.bool,
  patientId: PropTypes.string,
  orderTaskIds: PropTypes.arrayOf(PropTypes.string)
};

export default AddClipboardItem;
