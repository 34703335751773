import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "utils/dates";
import Table from "components/ui/impl/table";
import Header from "components/order-profile/header";
import { useFetch } from "hooks";
import { get } from "utils/api";

const AccessLogs = ({ orderId }) => {
  const fetchOrderAccessLogsApi = useFetch({
    apiFn: orderId => get(`orders/${orderId}/logs/access`),
    defaultValue: []
  });

  React.useEffect(() => {
    fetchOrderAccessLogsApi.fetchData(orderId);
  }, [orderId]);

  const accessLogs = fetchOrderAccessLogsApi.response;

  return (
    <div>
      <Header showSpinner={fetchOrderAccessLogsApi.isFetching}>
        Access Logs
      </Header>
      <Table
        rows={accessLogs}
        rowKey="id"
        columns={[
          {
            header: "Accessed By",
            field: "accessedByName",
            format: ({ accessedByName }) => accessedByName || "null"
          },
          {
            header: "Accessed Time",
            field: "accessedDate",
            format: ({ accessedDate }) =>
              formatDate(accessedDate, "MMM DD, YYYY hh:mm A")
          },
          {
            header: "Accessed From URL",
            field: "accessedFromURL"
          },
          {
            header: "Accessed From IP",
            field: "accessedFromIP"
          }
        ]}
      />
    </div>
  );
};

AccessLogs.propTypes = {
  orderId: PropTypes.string.isRequired
};

export default AccessLogs;
