import React from "react";
import PropTypes from "prop-types";
import Table from "components/ui/impl/table";
import Header from "components/order-profile/header";
import { formatDate } from "utils/dates";
import { useFetch } from "hooks";
import { get } from "utils/api";

const OrderChangeLogs = ({ orderId }) => {
  const fetchOrderChangeLogsApi = useFetch({
    apiFn: orderId => get(`orders/${orderId}/logs/change`),
    defaultValue: []
  });
  React.useEffect(() => {
    fetchOrderChangeLogsApi.fetchData(orderId);
  }, [orderId]);
  const changeLogs = fetchOrderChangeLogsApi.response;
  return (
    <div>
      <Header showSpinner={fetchOrderChangeLogsApi.isFetching}>
        Change Logs
      </Header>
      <Table
        rows={changeLogs}
        rowKey="id"
        columns={[
          {
            header: "Changed By",
            field: "changedByName",
            format: ({ changedByName }) => changedByName || "null"
          },
          {
            header: "Changed Time",
            field: "changedDate",
            format: ({ changedDate }) =>
              formatDate(changedDate, "MMM DD, YYYY hh:mm A")
          },
          {
            header: "Patient",
            field: "patientFirstName",
            format: ({ patientFirstName, patientLastName }) =>
              `${patientFirstName} ${patientLastName}`
          },
          {
            header: "Changed Field",
            field: "changedField"
          },
          {
            header: "Changed From",
            field: "oldValue"
          },
          {
            header: "Changed To",
            field: "newValue"
          }
        ]}
      />
    </div>
  );
};
OrderChangeLogs.propTypes = {
  orderId: PropTypes.string.isRequired
};

export default OrderChangeLogs;
