import { formatDate } from "utils/misc";

const convertValue = value => {
  switch (value) {
    case "false":
      return false;
    case "true":
      return true;
    default:
      return value;
  }
};

const getInitialValues = ({
  patientId,
  eligibleSupplies,
  supplyPeriodOptions,
  productLine,
  patientInfo,
  last_order,
  questions
}) => {
  // const lastOrderQuestions = Object.keys(last_order?.questions ?? {}).reduce(
  //   (prev, cur) => ({
  //     ...prev,
  //     [cur]: convertValue(last_order?.questions[cur])
  //   }),
  //   {}
  // );

  var lastOrderAnswers = questions
    ?.filter(x => x.default_value == "last_order")
    .reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: convertValue(last_order?.questions[curr.id])
      }),
      {}
    );

  const defaultDysfuctionAnswers = questions
    ?.filter(x => x.category.includes("Dysfunctions|"))
    ?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: true
      }),
      {}
    );

  return {
    patient_id: patientId,
    supply_period: String(
      Array.isArray(supplyPeriodOptions)
        ? (supplyPeriodOptions[supplyPeriodOptions?.length - 1]?.value ?? 90)
        : 90
    ),
    product_line: productLine,
    order_create_method: "Web App",
    address_change_type: "P",
    questions: {
      ...lastOrderAnswers,
      ...defaultDysfuctionAnswers,
      address_change: false,
      insurance_change: null,
      physician_change: false,
      change_in_supplies: false,
      additional_info: ""
    },
    requested_equipment: eligibleSupplies,
    physician: patientInfo?.physician ?? {
      npi: "",
      name: "",
      address: {
        street_address: "",
        city: "",
        zip: "",
        state: ""
      },
      phone: ""
    },
    insurances:
      patientInfo?.insurances?.map(insurance => ({
        ...insurance,
        policy_start_date: formatDate(insurance.policy_start_date, "YYYY/MM/DD")
      })) ?? [],
    address: patientInfo.address ?? {
      street_address: "",
      city: "",
      zip: "",
      state: ""
    }
  };
};

export default getInitialValues;
