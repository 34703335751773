import React from "react";
import PropTypes from "prop-types";
import { STATES } from "utils/enum";
import { connect } from "react-redux";
import { compose, setPropTypes } from "recompose";
import { Field, reduxForm, getFormValues, FormSection } from "redux-form";
import FormField from "components/forms/form-field";
import Checkbox from "components/forms/checkbox";
import LeftAlignedCheckbox from "components/forms/checkbox-left-aligned";
import SearchableDropdown from "components/searchable-dropdown";
import {
  normalizePhone,
  formatPhone,
  isValidPhone,
  isValidZip,
  isValidEmail,
  isRequired
} from "utils/redux-form";
import HasRolePermission from "components/shared/has-role-permission";
import IoIosInformationCircleOutline from "react-icons/lib/io/ios-information";
import { Tooltip } from "components/ui/tool-tip/tooltip";
import { useLocation } from "react-router-dom";
import { get } from "utils/api";
import { useFetch } from "hooks";
import DateField from "components/forms/date-field";

const requiredIfIvrCompany = (value, formValues) => {
  if (formValues.ivr_company) return isRequired(value);
};

const isValidIvrEndTime = (value, formValues) => {
  if (formValues.ivr_start > value) return "Invalid end time";
};
const isValidOutreachEndTime = (value, formValues) => {
  if (formValues.outreach_start_time > value) return "Invalid end time";
};
const isValidOfficeEndTime = (value, formValues) => {
  if (formValues.office_hours_start > value) return "Invalid end time";
};
const required = value => (value ? undefined : "Required");

const form = "company-form";
const getFormState = state => state.get("form");

const CompanyForm = compose(
  setPropTypes({
    valid: PropTypes.bool,
    ivr_start: PropTypes.string,
    initialValues: PropTypes.object,
    ivr_end: PropTypes.string,
    ivr_company: PropTypes.bool,
    teamOptions: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    userRole: PropTypes.string.isRequired,
    formValues: PropTypes.object,
    isNonAdmin: PropTypes.bool
  }),
  reduxForm({
    form,
    getFormState
  }),
  connect((state, { userRole }) => {
    const isNonAdmin = userRole !== "Administrator";
    const formValues = getFormValues("company-form", state =>
      state.get("form")
    )(state);
    return {
      isNonAdmin,
      formValues
    };
  })
)(
  ({
    valid,
    teamOptions,
    companyOptions,
    handleSubmit,
    isNonAdmin,
    companyId,
    handleCancel,
    change,
    formValues
  }) => {
    const {
      response: insurances,
      fetchData: getCompanyInsurances
      //isFetching: isFetchingInsurance
    } = useFetch({
      defaultValue: [],
      apiFn: companyGuid => get(`companies/${companyGuid}/insurances`)
    });
    React.useEffect(() => {
      if (companyId) getCompanyInsurances(companyId);
    }, [companyId]);
    const location = useLocation();
    return (
      <form onSubmit={handleSubmit} className="company-form">
        <fieldset>
          <legend>Company</legend>
          <Field
            name="company_name"
            label="Company *"
            component={FormField}
            validate={[isRequired]}
            disabled={isNonAdmin}
          />
          <HasRolePermission allowedRoles={["Administrator"]}>
            <Field
              name="company_friendly_name"
              label={
                <span>
                  Friendly Name{" "}
                  <Tooltip
                    id={`friendly_name`}
                    className="helpful-link-tooltip"
                    effect="solid"
                    message="Friendly name is what the IVR will read as the company name. Used to avoid pronouncing characters and symbols."
                  >
                    <IoIosInformationCircleOutline size="1rem" />
                  </Tooltip>
                </span>
              }
              component={FormField}
              disabled={isNonAdmin}
            />
          </HasRolePermission>
          <Field
            name="account_number"
            label="Nonfulfillment Account Number *"
            component={FormField}
            validate={[isRequired]}
            disabled={isNonAdmin}
          />
          <Field
            label="Outbound Phone Number *"
            name="outbound_phone_number"
            component={FormField}
            format={formatPhone}
            parse={normalizePhone}
            validate={[isValidPhone]}
            disabled={isNonAdmin}
          />
          <Field
            label="Reimbursement multiplier *"
            name="reimbursement_multiplier"
            component={FormField}
            validate={[isRequired]}
            disabled={isNonAdmin}
          />
          <Field
            label={
              <span>
                HCPC Eligibility Offset{" "}
                <Tooltip
                  id={`hcpc_eligibility_offset`}
                  className="helpful-link-tooltip"
                  effect="solid"
                  message="HCPC eligibility offset represents how many days a patient's line item eligibility date will be pushed when calculated from the order date and insurance rule. Negative values will set the eligibility date closer to the present."
                >
                  <IoIosInformationCircleOutline size="1rem" />
                </Tooltip>
              </span>
            }
            name="hcpc_eligibility_offset"
            component={FormField}
          />
          <Field
            label="Team *"
            name="team_name"
            as="select"
            options={teamOptions}
            component={FormField}
            validate={[isRequired]}
            nullOption
            disabled={isNonAdmin}
          />
          <Field
            label="PAP Resuppy Email *"
            name="pap_resupply_email_address"
            component={FormField}
            validate={[isRequired, isValidEmail]}
            disabled={isNonAdmin}
          />
          <HasRolePermission allowedRoles={["Administrator"]}>
            <div
              style={{
                margin: "10px 0px"
              }}
            >
              <Field
                label="Include Pricing"
                name="include_pricing"
                component={Checkbox}
              />
              <Field
                label="Uses Fulfillment"
                name="uses_fulfillment"
                component={Checkbox}
              />
              <Field
                label="Purchases equipment from S3"
                name="s3_middleman"
                component={Checkbox}
              />
              <Field
                label="Tracks Compliance"
                name="tracks_compliance"
                component={Checkbox}
              />
              <Field
                label="Review Autofit Results"
                name="review_autofit_results"
                component={Checkbox}
              />
            </div>
          </HasRolePermission>
        </fieldset>
        <HasRolePermission allowedRoles={["Administrator"]}>
          {formValues?.uses_fulfillment ? (
            <fieldset>
              <legend>Fulfillment companies</legend>
              <div>
                <div>
                  {formValues?.uses_fulfillment &&
                  formValues?.branches?.some(
                    x => x.branch_name?.toLowerCase() !== "unassigned"
                  ) &&
                  !location?.pathname.includes("/companies/new") ? (
                    <Field
                      label="Uses Branch Level Fulfillment Account Numbers"
                      name="bUsesBranchFulfillment"
                      component={Checkbox}
                    />
                  ) : (
                    <div
                      style={{
                        display: "inline",
                        color: "red",
                        fontWeight: "bold"
                      }}
                    >
                      Fulfillment currently only available at the company level.
                      In order to use branch level fulfillment, this company
                      must first have branches.
                    </div>
                  )}
                  {formValues?.uses_fulfillment &&
                  formValues?.bUsesBranchFulfillment ? (
                    <div
                      style={{
                        display: "inline",
                        color: "red",
                        fontWeight: "bold"
                      }}
                    >
                      Please visit each branch and ensure account numbers are
                      accurate
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "no-wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "35rem"
                  }}
                >
                  <Field
                    label="CPAP Dropship"
                    name="uses_cpap_fulfillment"
                    component={Checkbox}
                    disabled={isNonAdmin}
                  />
                  {!formValues?.bUsesBranchFulfillment &&
                  formValues?.uses_cpap_fulfillment ? (
                    <Field
                      label="CPAP Dropship Account Number"
                      name="cpap_account_number"
                      as="input"
                      type="text"
                      validate={
                        formValues?.uses_cpap_fulfillment &&
                        !formValues?.bUsesBranchFulfillment && [required]
                      }
                      component={FormField}
                      style={{ width: "20rem" }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "no-wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "35rem"
                  }}
                >
                  <Field
                    label="Independence Medical"
                    name="uses_independence_fulfillment"
                    component={Checkbox}
                    disabled={isNonAdmin}
                  />
                  {!formValues?.bUsesBranchFulfillment &&
                  formValues?.uses_independence_fulfillment ? (
                    <Field
                      label="Independence Dropship Account Number"
                      name="independance_account_number"
                      as="input"
                      type="text"
                      validate={
                        formValues?.uses_independence_fulfillment &&
                        !formValues?.bUsesBranchFulfillment && [required]
                      }
                      component={FormField}
                      style={{ width: "20rem" }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "no-wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "35rem"
                  }}
                >
                  <Field
                    label="Internal"
                    name="uses_internal_fulfillment"
                    component={Checkbox}
                    disabled={isNonAdmin}
                  />
                  {!formValues?.bUsesBranchFulfillment &&
                  formValues?.uses_internal_fulfillment ? (
                    <Field
                      label="Internal Account Number"
                      name="internal_account_number"
                      as="input"
                      type="text"
                      validate={
                        formValues?.uses_internal_fulfillment &&
                        !formValues?.bUsesBranchFulfillment && [required]
                      }
                      component={FormField}
                      style={{ width: "20rem" }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "no-wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "35rem"
                  }}
                >
                  <Field
                    label="McKesson"
                    name="uses_mckesson_fulfillment"
                    component={Checkbox}
                    disabled={isNonAdmin}
                  />
                  {!formValues?.bUsesBranchFulfillment &&
                  formValues?.uses_mckesson_fulfillment ? (
                    <Field
                      label="McKesson Account Number"
                      name="mckesson_account_number"
                      as="input"
                      type="text"
                      validate={
                        formValues?.uses_mckesson_fulfillment &&
                        !formValues?.bUsesBranchFulfillment && [required]
                      }
                      component={FormField}
                      style={{ width: "20rem" }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "no-wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "35rem"
                  }}
                >
                  <Field
                    label="PPM"
                    name="uses_ppm_fulfillment"
                    component={Checkbox}
                  />
                  {!formValues?.bUsesBranchFulfillment &&
                  formValues?.uses_ppm_fulfillment ? (
                    <Field
                      label="PPM Account Number"
                      name="ppm_account_number"
                      as="input"
                      type="text"
                      validate={
                        formValues?.uses_ppm_fulfillment &&
                        !formValues?.bUsesBranchFulfillment && [required]
                      }
                      component={FormField}
                      style={{ width: "20rem" }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "no-wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "35rem"
                  }}
                >
                  <Field
                    label="VGM"
                    name="uses_vgm_fulfillment"
                    component={Checkbox}
                    disabled={isNonAdmin}
                  />
                  {!formValues?.bUsesBranchFulfillment &&
                  formValues?.uses_vgm_fulfillment ? (
                    <Field
                      label="VGM Account Number"
                      name="vgm_account_number"
                      as="input"
                      type="text"
                      validate={
                        formValues?.uses_vgm_fulfillment &&
                        !formValues?.bUsesBranchFulfillment && [required]
                      }
                      component={FormField}
                      style={{ width: "20rem" }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </fieldset>
          ) : (
            ""
          )}
        </HasRolePermission>
        <fieldset>
          <legend>Insurance</legend>
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Field
              label="NPI"
              name="insurance.npi"
              component={FormField}
              disabled={isNonAdmin}
            />
            <Field
              label="Tax Id"
              name="insurance.tax_id"
              component={FormField}
              disabled={isNonAdmin}
            />
            <Field
              label="Taxonomy Code"
              name="insurance.taxonomy_code"
              component={FormField}
              disabled={isNonAdmin}
            />
          </div>
        </fieldset>
        <HasRolePermission allowedRoles={["Administrator"]}>
          <fieldset>
            <legend>Company Status</legend>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(5, auto)",
                gridGap: 10
              }}
            >
              <Field
                label="Active"
                name="company_active"
                component={Checkbox}
              />
              <Field
                label="Access On Hold"
                name="company_access_hold"
                component={Checkbox}
                disabled={isNonAdmin}
              />
              <Field
                label="Outreach On Hold"
                name="company_outreach_hold"
                component={Checkbox}
                disabled={isNonAdmin}
              />
              <Field
                label="Review Orders"
                name="order_review"
                component={Checkbox}
                disabled={isNonAdmin}
              />
              <Field
                label="Autofill Order Line Items"
                name="autofill_order_line_items"
                component={Checkbox}
                disabled={isNonAdmin}
              />
              <Field
                label="Software As Service"
                name="software_as_service"
                component={Checkbox}
                disabled={isNonAdmin}
              />
              {/* <Field
            label="Order Loading"
            name="order_loading"
            component={Checkbox}
            disabled={isNonAdmin}
          /> */}
              <Field
                label="Contractual Text Consent"
                name="AutoTextConsent"
                component={Checkbox}
                disabled={isNonAdmin}
              />
              <Field
                label="Contracted Viewing"
                name="allows_contracted_viewing"
                component={Checkbox}
                disabled={isNonAdmin}
              />
              <div>
                <Field
                  label="Allow Contracted Inbound"
                  name="settings.bAllowsContractedInbound"
                  component={Checkbox}
                  disabled={isNonAdmin}
                />
                <Tooltip
                  id={`contracted_inbound`}
                  className="helpful-link-tooltip"
                  effect="solid"
                  message={
                    <div>
                      When left unchecked, will let patients marked as
                      &quot;Offshore Limited&quot; go to the IVR (if Queue Can
                      Transfer
                      <br />
                      to IVR is checked) or leave a voicemail. This is so they
                      do not potentially connect to an offshore coach.
                    </div>
                  }
                >
                  <IoIosInformationCircleOutline size="1rem" />
                </Tooltip>
              </div>

              <Field
                label="Philips Recall Prompt"
                name="phillips_recall_prompt"
                component={Checkbox}
                disabled={isNonAdmin}
              />
              <Field
                label="Records Calls"
                name="settings.bRecordsCalls"
                component={Checkbox}
                disabled={isNonAdmin}
              />
              <Field
                label="Queue Can Transfer to IVR"
                name="settings.bAllowIVRTransferFromQueue"
                component={Checkbox}
                disabled={isNonAdmin}
              />

              <div>
                <Field
                  label="Billed Quantity for Eligibility"
                  name="settings.bHCPCEligibilityBilledQty"
                  component={Checkbox}
                />
                <Tooltip
                  id="billed_elig_qty"
                  className="helpful-link-tooltip"
                  effect="solid"
                  message={
                    "Use billed quantity for eligibility calculation if checked or unchecked to use shipped quantity for eligiblity."
                  }
                >
                  <IoIosInformationCircleOutline size="1rem" />
                </Tooltip>
              </div>
              <Field
                label="Automate Welcome Email"
                name="settings.bAutomateResupplyEmail"
                component={Checkbox}
              />
              <Field
                label="Ignores Expired Form Task"
                name="settings.bSkipExpiredFormTask"
                component={Checkbox}
              />
              <Field
                  label="Automate Daily Technology Outreach"
                  name="settings.bAutomatedTechOutreach"
                  component={Checkbox}
                />
            </div>
            <div style={{ marginTop: "10px" }}>
              <Field
                label="Deductible Renewing Reminder"
                name="settings.bNotifyDeductibleRenewingJanuary"
                component={Checkbox}
              />
              <Tooltip
                id={`deductible_renewing_reminder`}
                className="helpful-link-tooltip"
                effect="solid"
                message={
                  <div>
                    When checked, will remind patients that deductibles will
                    reset at the beginning of the new year. Will only affect
                    outreach
                    <br />
                    that happens from October through December. Set the last day
                    patients can order or leave it blank for a generic message.
                  </div>
                }
              >
                <IoIosInformationCircleOutline size="1rem" />
              </Tooltip>
              {formValues?.settings.bNotifyDeductibleRenewingJanuary && (
                <Field
                  label="Last Day to Order"
                  type="date"
                  name="settings.dDeductibleReminder"
                  // as="input"
                  component={DateField}
                />
              )}
            </div>
          </fieldset>
          <fieldset>
            <legend>Order Loading</legend>
            <Field
              label="Use Order Loading"
              name="order_loading"
              component={Checkbox}
              disabled={isNonAdmin}
            />
            <Field
              label="Email"
              name="order_loading_email"
              component={Checkbox}
              disabled={isNonAdmin}
            />
            <Field
              label="IVR"
              name="order_loading_IVR"
              component={Checkbox}
              disabled={isNonAdmin}
            />
            <Field
              label="Mobile App"
              name="order_loading_mobile_app"
              component={Checkbox}
              disabled={isNonAdmin}
            />
            <Field
              label="Patient Portal"
              name="order_loading_patient_portal"
              component={Checkbox}
              disabled={isNonAdmin}
            />
            <Field
              label="Web App"
              name="order_loading_web_app"
              component={Checkbox}
              disabled={isNonAdmin}
            />
          </fieldset>
          <fieldset>
            <legend>Company Integration</legend>
            <Field
              label="Bonafide Integration"
              name="bonafide_api"
              component={Checkbox}
              disabled={isNonAdmin}
            />
            <Field
              label="Niko Health Integration"
              name="nikohealth_api"
              component={Checkbox}
              disabled={isNonAdmin}
            />
            <Field
              label="RA Integration"
              name="ra_integration"
              component={Checkbox}
              disabled={isNonAdmin}
            />
          </fieldset>
          <fieldset>
            <legend>Patient Type Management</legend>
            {formValues?.patient_type_settings?.map((x, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "50%"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexBasis: "100%",
                      flex: "1"
                    }}
                  >
                    <Field
                      label={`Manages ${x.sPatientTypeLabel}`}
                      name={`patient_type_settings[${idx}].bActive`}
                      component={Checkbox}
                      disabled={isNonAdmin}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexBasis: "100%",
                      flex: "2"
                    }}
                  >
                    <Field
                      label={
                        <span>
                          {`${x.sPatientTypeLabel} Outreach Offset `}
                          <Tooltip
                            id={`${x.sPatientTypeName}_tooltip`}
                            className="helpful-link-tooltip"
                            effect="solid"
                            message="Outreach offset represents how many days a patient's outreach date will pushed upon being created."
                          >
                            <IoIosInformationCircleOutline size="1rem" />
                          </Tooltip>
                        </span>
                      }
                      name={`patient_type_settings[${idx}].iOutreachOffset`}
                      component={FormField}
                      disabled={isNonAdmin}
                    />
                  </div>
                </div>
              );
            })}
          </fieldset>
        </HasRolePermission>
        <fieldset>
          <legend>Address</legend>
          <FormSection name="address" className="address-fields">
            <Field
              label="Street Address"
              name="street_address"
              className="street-address"
              component={FormField}
              style={{
                gridColumn: "1 / -1"
              }}
              disabled={isNonAdmin}
            />
            <Field
              label="City"
              name="city"
              as="input"
              type="text"
              component={FormField}
              disabled={isNonAdmin}
            />
            <Field
              label="State"
              name="state"
              as="select"
              options={STATES}
              component={FormField}
              nullOption
              disabled={isNonAdmin}
            />
            <Field
              label="Zip Code"
              name="zip"
              as="input"
              type="number"
              component={FormField}
              validate={[isValidZip]}
              disabled={isNonAdmin}
            />
          </FormSection>
        </fieldset>
        <HasRolePermission allowedRoles={["Administrator"]}>
          <fieldset>
            <legend>IVR</legend>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto auto ",
                gridTemplateRows: "auto",
                alignItems: "center",
                gridGap: 20,
                marginBottom: 10
              }}
            >
              <Field
                label="Ivr Company"
                name="ivr_company"
                component={Checkbox}
              />
              <Field
                label="Uses Branch Names"
                name="reads_branch_names"
                component={Checkbox}
              />
              <Field
                label="Company Handles Problems"
                name="company_handles_ivr_problems"
                component={Checkbox}
              />
              <Field
                label="Handles Orders"
                name="company_handles_orders"
                component={Checkbox}
              />
              <Field
                label="Verbal Response Allowed"
                name="is_verbal_IVR"
                component={Checkbox}
              />
              <div style={{ display: "flex" }}>
                <Tooltip
                  id={`opt_out_dnc`}
                  className="helpful-link-tooltip"
                  effect="solid"
                  message="When checked, patients who opt out of IVR/Text/Email will be set as Do Not Contact instead of Manual Call. IVR only."
                >
                  <IoIosInformationCircleOutline size="1rem" />
                </Tooltip>
                <Field
                  label="Opt Out Yields DNC"
                  name="settings.bOptOutYieldsDNC"
                  component={Checkbox}
                />
              </div>
              <div style={{ display: "flex" }}>
                <Field
                  label="Read Address Line 2"
                  name="settings.bReadAddressLine2"
                  component={Checkbox}
                />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridGap: 20
              }}
            >
              <Field
                label="Start Time (CST)"
                name="ivr_start"
                validate={[requiredIfIvrCompany]}
                as="input"
                type="time"
                step="3600000"
                component={FormField}
              />
              <Field
                label="End Time (CST)"
                name="ivr_end"
                validate={[requiredIfIvrCompany, isValidIvrEndTime]}
                as="input"
                type="time"
                step="3600000"
                component={FormField}
              />
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridGap: 20
              }}
            >
              <Field
                label="IVR Outbound Number"
                name="ivr_outbound_phone_number"
                type="tel"
                as="input"
                component={FormField}
                format={formatPhone}
                parse={normalizePhone}
                validate={[isValidPhone, requiredIfIvrCompany]}
              />
              <Field
                label="Problem Transfer Number"
                name="ivr_company_transfer_number"
                type="tel"
                as="input"
                component={FormField}
                format={formatPhone}
                parse={normalizePhone}
                validate={[isValidPhone]}
              />
              <Field
                label="Daily Order Goal"
                name="ivr_order_goal"
                type="number"
                as="input"
                component={FormField}
                validate={[requiredIfIvrCompany]}
              />
            </div>
            <fieldset
              style={{
                border: "none",
                borderTop: "1px solid #ddd"
              }}
            >
              <legend>Settings</legend>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gridGap: 20
                }}
              >
                <Field
                  label="Number Of Patients To Pull From IVR Batch"
                  name="number_of_patients_to_pull_from_ivr_batch"
                  type="number"
                  as="input"
                  component={FormField}
                />
                <Field
                  label="Number Of Patients To Call Per Request"
                  name="number_of_patients_to_call_per_request"
                  type="number"
                  as="input"
                  component={FormField}
                />
                <Field
                  label="Max Total Threads Per Request"
                  name="max_total_threads_per_request"
                  type="number"
                  as="input"
                  component={FormField}
                />
              </div>
              <div>
                <Field
                  label="Pause IVR"
                  name="pause_ivr"
                  component={Checkbox}
                />
                <Field
                  label="Limit Patient Calls For Request"
                  name="limit_patient_calls_for_request"
                  component={Checkbox}
                />
              </div>
            </fieldset>
          </fieldset>
        </HasRolePermission>

        <HasRolePermission allowedRoles={["Administrator"]}>
          <fieldset>
            <legend>Variable Outreach</legend>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "50px"
                }}
              >
                <span>
                  Outreach Methods
                  <Tooltip
                    id={`variable_outreach_methods`}
                    className="helpful-link-tooltip"
                    effect="solid"
                    message="Select which contact methods should use variable outreach logic on order creation."
                  >
                    <IoIosInformationCircleOutline size="1rem" />
                  </Tooltip>
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap"
                  }}
                >
                  <Field
                    label="Manual Call"
                    name="settings.bVariableOutreachManualCall"
                    component={LeftAlignedCheckbox}
                  />
                  <Field
                    label="IVR"
                    name="settings.bVariableOutreachIVR"
                    component={LeftAlignedCheckbox}
                  />
                  <Field
                    label="Patient App / Text / Email"
                    name="settings.bVariableOutreachPatientPortal"
                    component={LeftAlignedCheckbox}
                  />
                </div>
              </div>
              <div>
                <span>
                  Product Types{" "}
                  <Tooltip
                    id={`variable_outreach_products`}
                    className="helpful-link-tooltip"
                    effect="solid"
                    message="When a patient doesn't order an eligible selected item, push patient outreach for 30 days."
                  >
                    <IoIosInformationCircleOutline size="1rem" />
                  </Tooltip>
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    height: "80px"
                  }}
                >
                  <Field
                    label="Mask"
                    name="settings.bContactIn30Mask"
                    component={LeftAlignedCheckbox}
                  />
                  <Field
                    label="Seals / Cushions / Pillows"
                    name="settings.bContactIn30Seals"
                    component={LeftAlignedCheckbox}
                  />
                  <Field
                    label="Chinstrap"
                    name="settings.bContactIn30Chinstrap"
                    component={LeftAlignedCheckbox}
                  />
                  <Field
                    label="Filters"
                    name="settings.bContactIn30Filter"
                    component={LeftAlignedCheckbox}
                  />
                  <Field
                    label="Headgear"
                    name="settings.bContactIn30Headgear"
                    component={LeftAlignedCheckbox}
                  />
                  <Field
                    label="Tubing"
                    name="settings.bContactIn30Tubing"
                    component={LeftAlignedCheckbox}
                  />
                  <Field
                    label="Waterchamber"
                    name="settings.bContactIn30Waterchamber"
                    component={LeftAlignedCheckbox}
                  />
                </div>
              </div>
            </div>
          </fieldset>
        </HasRolePermission>
        <fieldset>
          <legend>Administration</legend>
          <HasRolePermission allowedRoles={["Administrator"]}>
            <Field
              label="Parent Company"
              name="company_relationships.parent_guid"
              as="select"
              options={companyOptions}
              component={FormField}
              nullOption
              disabled={isNonAdmin}
            />
          </HasRolePermission>
          <Field
            label="Outreach Start Date"
            type="date"
            as="input"
            component={DateField}
            name="service_start_date"
            disabled={isNonAdmin}
          />
          {/* <Field */}
          {/*   label="Last Billed Date" */}
          {/*   type="date" */}
          {/*   as="input" */}
          {/*   component={FormField} */}
          {/*   name="last_billed_date" */}
          {/* /> */}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: 10
            }}
          >
            <Field
              label="Outreach Start Time (CST)"
              name="outreach_start_time"
              as="input"
              type="time"
              step="3600000"
              component={FormField}
              disabled={isNonAdmin}
            />
            <Field
              label="Outreach End Time (CST)"
              name="outreach_end_time"
              validate={[isValidOutreachEndTime]}
              as="input"
              type="time"
              step="3600000"
              component={FormField}
              disabled={isNonAdmin}
            />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: 10
            }}
          >
            <Field
              label="Office Hours Start"
              name="office_hours_start"
              as="input"
              type="time"
              step="3600000"
              component={FormField}
              disabled={isNonAdmin}
            />
            <Field
              label="Office Hours End"
              name="office_hours_end"
              validate={[isValidOfficeEndTime]}
              as="input"
              type="time"
              step="3600000"
              component={FormField}
              disabled={isNonAdmin}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend>Importer Settings</legend>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: 10
            }}
          >
            <Field
              label="Uses Payor ID"
              name="uses_payor_id"
              component={Checkbox}
            />
            <Field
              label="Demographics File Only Updates Existing Accounts"
              name="demographics_only_update_existing"
              component={Checkbox}
            />
            <div>
              <label>New Insurance Template</label>
              <SearchableDropdown
                disabled={isNonAdmin}
                options={[
                  { text: "Medicare", value: "" },
                  ...insurances.map(x => ({
                    text: x.sInsuranceCompanyName,
                    value: x.sMGInsuranceID
                  }))
                ]}
                selected={formValues?.insurance_template_id}
                onSelect={value => {
                  change("insurance_template_id", value);
                }}
              />
            </div>
          </div>
        </fieldset>
        <div className="text-right">
          {handleCancel && (
            <button
              className="button alert"
              onClick={handleCancel}
              style={{ marginRight: "20px" }}
            >
              Cancel
            </button>
          )}
          <button className="button" type="submit" disabled={!valid}>
            Submit
          </button>
        </div>
      </form>
    );
  }
);

export default CompanyForm;
