import React, { useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { PatientProfileContext } from "components/profile/context";
import { createPatientOrderNew } from "actions/order-form";
import { InlineWaiting } from "components/ui";
import ChooseSupplies from "./choose-supplies";
import { useFetch } from "hooks";
import FormElement from "./form-elements";
import { Formik, Field, Form } from "formik";
import { post } from "utils/api";
import Dysfunctions from "./dysfunctions";
import PhysicianForm from "./physician";
import InsuranceForm from "./insurance";
import AddressForm from "./address";
import RadioGroupField from "components/forms/radio-group-field";
import OrderFormValidationSchema from "./validation";
import { isRequired } from "utils/redux-form";
import getInitialValues from "./initial-values";

const OrderFormNew = ({
  patientId,
  productLine,
  patientInfo,
  handleOrderFormX,
  handleCreateOrderSuccess
}) => {
  const getOrderFormInfoAPI = useFetch({
    apiFn: ({ patientId, productLine }) =>
      post("orders/order_form", { patientId, productLine })
  });

  const submitOrderNewAPI = useFetch({
    apiFn: values => post("create-new-order", values),
    onSuccess: handleCreateOrderSuccess
  });

  React.useEffect(() => {
    getOrderFormInfoAPI.fetchData({ patientId, productLine });
  }, [patientId, productLine, patientInfo]);

  const { companyInsurances, fetchPatientHCPCHistoryAPI } = useContext(
    PatientProfileContext
  );

  const patientHCPCHistory =
    fetchPatientHCPCHistoryAPI?.response?.eligiblity ?? [];

  var groupedQuestions =
    getOrderFormInfoAPI?.response?.questions?.reduce((acc, question) => {
      let groupKey = question.category;
      if (groupKey.includes("Dysfunctions|")) {
        const productType = question.category.split("|")[1] ?? "";
        return {
          ...acc,
          Dysfunctions: [
            ...(acc?.Dysfunctions ?? []),
            { ...question, productType }
          ]
        };
      }

      if (!acc[groupKey]) {
        acc[groupKey] = [{ ...question }];
      } else {
        acc[groupKey] = [...acc[groupKey], { ...question }];
      }
      return acc;
    }, {}) ?? {};

  const equipmentOffered = getOrderFormInfoAPI?.response?.supply_options ?? [];

  const supplyPeriodOptions = (
    getOrderFormInfoAPI?.response?.supply_period_options ?? []
  ).map(x => ({
    text: `${x} Day Supply`,
    value: String(x)
  }));

  const last_order = getOrderFormInfoAPI?.response?.last_order;

  /*
  const defaultDysfuctionAnswers = groupedQuestions.Dysfunctions?.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.id]: true
    }),
    {}
  );
  */

  const eligibleSupplies = patientHCPCHistory
    .filter(
      x =>
        x.isEligible &&
        x.productType &&
        equipmentOffered.includes(x.productType.toLowerCase())
    )
    .map(x => x.productType.toLowerCase());

  if (getOrderFormInfoAPI.isFetching)
    return (
      <div
        style={{
          display: "flex",
          height: 300,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <InlineWaiting />
      </div>
    );
  if (getOrderFormInfoAPI.error)
    return (
      <div
        style={{
          display: "flex",
          fontSize: 16,
          color: "red",
          height: 300,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        Error Loading order form
      </div>
    );

  const validationSchema = OrderFormValidationSchema(groupedQuestions);

  const initialValues = getInitialValues({
    patientId,
    eligibleSupplies,
    supplyPeriodOptions,
    productLine,
    patientInfo,
    last_order,
    questions: getOrderFormInfoAPI?.response?.questions ?? []
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      initialTouched={{
        requested_equipment: true,
        address: {
          street_address: true,
          city: true,
          zip: true,
          state: true
        },
        questions: {
          insurance_change: true,
          office_pickup: true,
          change_in_supplies: true,
          outside_purchase: true,
          caregiver_name: true
        },
        reason_for_no_mask: true
      }}
      onSubmit={async values => {
        await submitOrderNewAPI.fetchData(values);
      }}
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <div className="orderFormContainer">
            {handleOrderFormX && (
              <div onClick={handleOrderFormX} className="orderFormX">
                &#x2715;
              </div>
            )}
            <h4>{productLine}: Order Form</h4>
            <ChooseSupplies
              equipmentOffered={equipmentOffered}
              patientHCPCHistory={patientHCPCHistory}
              eligibleSupplies={eligibleSupplies}
              patientInfo={patientInfo}
            />
            <hr />
            <h4> Please Review The Following Information</h4>
            <div>
              {groupedQuestions.Usage?.map(x => (
                <FormElement key={x.id} {...x} />
              ))}
            </div>
            <div>
              {groupedQuestions["Additional Info"]?.map(x => (
                <FormElement key={x.id} {...x} />
              ))}
            </div>
            <Dysfunctions questions={groupedQuestions} />
            <hr />
            <PhysicianForm
              physician={patientInfo?.physician ?? {}}
              questions={groupedQuestions.Physician ?? []}
            />
            <hr />
            <InsuranceForm
              insuranceOptions={companyInsurances ?? []}
              patientInsurances={patientInfo?.insurances ?? []}
              questions={groupedQuestions.Insurance ?? []}
            />
            <AddressForm
              patientInfo={patientInfo}
              address={patientInfo.address}
              questions={groupedQuestions.Address ?? []}
            />
            <div>
              <span style={{ flex: 1 }}>
                <Field
                  component={RadioGroupField}
                  name="supply_period"
                  label="How many days of supplies"
                  value={values.supply_period}
                  options={supplyPeriodOptions}
                  validate={isRequired}
                />
              </span>
              {groupedQuestions["Misc"]?.map(x => (
                <FormElement key={x.id} {...x} />
              ))}
            </div>
            {isSubmitting ? (
              <InlineWaiting />
            ) : (
              <button type="submit">Submit</button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default connect(
  (state, { patientId }) => ({
    patientInfo: selectors.getPatientInfo(state, patientId),
    userRole: selectors.getUserRole(state),
    insuranceOptions: selectors.getFormOptionsInsurance(state)
  }),
  { createPatientOrderNew }
)(OrderFormNew);

OrderFormNew.propTypes = {
  patientId: PropTypes.string.isRequired,
  productLine: PropTypes.string.isRequired,
  handleCreateOrderSuccess: PropTypes.func,
  patientInfo: PropTypes.object,
  handleOrderFormX: PropTypes.func
};
