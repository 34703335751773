import React from "react";
import PropTypes from "prop-types";
import { useLocalStorage } from "hooks";
import { MdArrowDownward, MdArrowUpward } from "react-icons/lib/md";
import ResizePanel from "./resize-panel";
import BonafideOrderPanel from "features/bonafide/components/order-profile";
import { OrderProfileContext } from "../context";
import OrderClipboard from "./clipboard";

const OrderPanel = ({ orderId }) => {
  const [isMinimized, setIsMinizmized] = useLocalStorage(
    "worklist-minimized",
    false
  );
  const [containerHeight, setContainerHeight] = useLocalStorage(
    "order-worklist-panel-height",
    115
  );

  const { order } = React.useContext(OrderProfileContext);

  function handleTabClick(tabLabel) {
    if (tabLabel === selectedTab) {
      setIsMinizmized(() => !isMinimized);
    } else {
      setIsMinizmized(false);
    }
    setSelectedTab(tabLabel);
  }

  const [selectedTab, setSelectedTab] = React.useState("tasks");
  const uses_bonafide_api = order.uses_bonafide_api;
  return (
    <React.Fragment>
      <div className="work-list-container">
        <div className={`work-list-header ${isMinimized ? "minimized" : ""}`}>
          <div className="header-text">
            <div className="order-worklist-tab-headers">
              <div
                className={`header-tab ${
                  selectedTab === "tasks" ? "active" : ""
                }`}
                onClick={() => handleTabClick("tasks")}
              >
                Clipboard
              </div>
              {uses_bonafide_api && (
                <div
                  className={`header-tab ${
                    selectedTab === "bonafide" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("bonafide")}
                >
                  Verify
                </div>
              )}
            </div>
          </div>
          <div
            className="minimize-order-worklist"
            onClick={() => setIsMinizmized(() => !isMinimized)}
          >
            <i className="flat-icon-button ripple">
              {isMinimized ? (
                <MdArrowDownward size={16} />
              ) : (
                <MdArrowUpward size={16} color="#666" />
              )}
            </i>
          </div>
        </div>
        {!isMinimized && (
          <React.Fragment>
            <div
              className="work-list-reasons scrollbar-dark"
              style={{
                height: containerHeight,
                minHeight: 65,
                maxHeight: "75vh"
              }}
            >
              {selectedTab === "tasks" && (
                <OrderClipboard
                  orderId={orderId}
                  orderStatus={order.statu}
                  patientId={order?.patient_id}
                />
              )}
              {uses_bonafide_api && (
                <BonafideOrderPanel
                  orderId={orderId}
                  visible={selectedTab === "bonafide"}
                />
              )}
            </div>
            <ResizePanel
              isMinimized={isMinimized}
              containerHeight={containerHeight}
              setContainerHeight={setContainerHeight}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default OrderPanel;

OrderPanel.propTypes = {
  orderId: PropTypes.string.isRequired
};
