import React from "react";
import PropTypes from "prop-types";
import { OrderProfileContext } from "./context";
import { InlineWaiting } from "components/ui";
import Header from "components/order-profile/header";

const EditSalesOrderNumber = ({ closeModal }) => {
  const { updateOrder, order, isUpdatingOrder } =
    React.useContext(OrderProfileContext);
  const [value, setValue] = React.useState(order.sales_order_number);
  return (
    <div className="sales-order-form" style={{ padding: 10 }}>
      <Header>Sales Order Number</Header>
      <form
        onSubmit={async event => {
          event.preventDefault();
          await updateOrder({ sales_order_number: value });
          closeModal();
        }}
      >
        <input
          type="text"
          value={value}
          onChange={event => setValue(event.target.value)}
          maxLength={70}
        />
        {isUpdatingOrder ? (
          <InlineWaiting />
        ) : (
          <div className="submit-container">
            <button type="submit">Submit</button>
          </div>
        )}
      </form>
    </div>
  );
};

EditSalesOrderNumber.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default EditSalesOrderNumber;
