import React, { useState } from "react";
import PropTypes from "prop-types";
import Header from "components/order-profile/header";
// import VerifyOrderProductCompatibility from "./verify-order-product-compatibility";
import { selectors } from "reducers";
import { useSelector } from "react-redux";
import ModalLink from "components/shared/modal-link";
import { InlineWaiting } from "components/ui";
import { QuantityInput, ZeroBilledInput } from "./inputs";
import { MdContentCopy } from "react-icons/lib/md";
import { IoClose } from "react-icons/lib/io";
import { OrderProfileContext } from "../context";
import { useFetch } from "hooks";
import { post, del } from "utils/api";

const OrderLineItems = ({ orderId }) => {
  const { fetchOrderLineItemsApi, order } =
    React.useContext(OrderProfileContext);

  const orderLineItems = fetchOrderLineItemsApi.response;

  const userRole = useSelector(selectors.getUserRole);

  const updateOrderLineItemByIdApi = useFetch({
    apiFn: values => post(`orders/${orderId}/update_line_item_by_id`, values),
    onSuccess: () => fetchOrderLineItemsApi.fetchData(orderId)
  });

  const deleteOrderLineItemApi = useFetch({
    apiFn: line_item_id => del(`orders/line_item/${line_item_id}`),
    onSuccess: () => fetchOrderLineItemsApi.fetchData(orderId)
  });
  const [animate, setAnimate] = useState({
    animate: false,
    index: 0
  });

  const CopyToClipboard = value => {
    navigator.clipboard.writeText(value);
  };

  const canRemoveLineItem = ![
    "Completed",
    "Pending Shipment",
    "Rejected"
  ].includes(order.order_status);

  const canChangeQuantities = [
    "Administrator",
    "CompanyAdministrator",
    "OfficeAdministrator",
    "ServiceAdmin",
    "ServiceCoach",
    "ContractorAdministrator"
  ].includes(userRole)
    ? [
        "New",
        "Accepted",
        "Coach Follow Up",
        "Incorrect",
        "Backordered",
        "On Hold",
        "Ready For Review",
        "Pending Buildout",
        "Order Loading"
      ].includes(order.order_status)
    : [
        "Pending",
        "Internal Processing",
        "Location Assistance Needed",
        "Integration Follow Up"
      ].includes(order.order_status);
  var hasLineWithProductType = equipmentType => {
    return orderLineItems.some(x =>
      x.product_type?.toLowerCase().includes(equipmentType?.toLowerCase())
    );
  };
  return (
    <div>
      <Header showSpinner={fetchOrderLineItemsApi.isFetching}>
        Equipment Requested:{" "}
        {order.equipment_requested?.map(x => (
          <span
            className={`equipment-label ${hasLineWithProductType(x) ? "hasLineItem" : ""}`}
            key={x}
          >
            {x}
          </span>
        ))}
      </Header>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th />
            <th />
            <th>Type</th>
            <th>Product ID</th>
            <th>Manufacturer</th>
            <th>HCPCS</th>
            <th>Product</th>
            <th>Qty</th>
            <th>Zero Billed</th>
          </tr>
        </thead>
        <tbody>
          {orderLineItems.map((x, idx) => (
            <tr
              key={x.line_item_id}
              className={
                order.flag_generic_items && x.item_name?.includes("Generic")
                  ? "generic-item-flag"
                  : ""
              }
            >
              <td style={{ width: 15 }}>
                {canRemoveLineItem && (
                  <>
                    {deleteOrderLineItemApi.isFetching ? (
                      <InlineWaiting />
                    ) : (
                      <i
                        onClick={() => {
                          console.log("remove: " + x.line_item_id);
                          deleteOrderLineItemApi.fetchData(x.line_item_id);
                        }}
                        className="remove-equipment-x"
                      >
                        <IoClose />
                      </i>
                    )}
                  </>
                )}
              </td>
              <td>
                {/* <VerifyOrderProductCompatibility */}
                {/*   orderEquipment={orderLineItems */}
                {/*     .map(({ inventoryGUID }) => inventoryGUID) */}
                {/*     .filter(lineItemGuid => lineItemGuid !== x.inventoryGUID)} */}
                {/*   back_ordered={x.back_ordered} */}
                {/*   equipmentId={x.inventoryGUID} */}
                {/*   patientEquipment={patientCurrentEquipment.map( */}
                {/*     ({ item_id }) => item_id */}
                {/*   )} */}
                {/*   show_generic_flag={ */}
                {/*     flag_generic_items && x.Product.includes("Generic") */}
                {/*   } */}
                {/* /> */}
              </td>
              <td>{x.product_type}</td>
              <td
                title={`${x.company_product_id ? "Manufacturer Id" : "Crosswalk Id"}`}
                style={{ display: "flex", alignItems: "baseline" }}
              >
                {x.company_product_id
                  ? x.company_product_id
                  : x.manufacturer_id}
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    marginLeft: 10,
                    color: "grey"
                  }}
                  className={`${
                    animate.animate && animate.index === idx
                      ? "copied-animation"
                      : ""
                  }`}
                  title="Copy To Clipboard"
                  onAnimationEnd={() =>
                    setAnimate({ animate: false, index: 0 })
                  }
                >
                  <MdContentCopy
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      CopyToClipboard(
                        x.company_product_id
                          ? x.company_product_id
                          : x.manufacturer_id
                      );
                      setAnimate({ animate: true, index: idx });
                    }}
                  />
                </div>
              </td>
              <td>{x.manufacturer}</td>
              <td>{x.hcpc}</td>
              <td>
                <ModalLink
                  to={`/companies/${x.company_id}/profile/inventory/${x.company_inventory_guid}`}
                >
                  {x.item_name}
                </ModalLink>
              </td>
              <td>
                <QuantityInput
                  orderId={orderId}
                  line_item_id={x.line_item_id}
                  quantity={x.quantity}
                  updateOrderLineItemByIdApi={updateOrderLineItemByIdApi}
                  canChangeQuantities={canChangeQuantities}
                />
              </td>
              <td>
                <ZeroBilledInput
                  orderId={orderId}
                  line_item_id={x.line_item_id}
                  value={x.zero_billed}
                  updateOrderLineItemByIdApi={updateOrderLineItemByIdApi}
                  canChangeQuantities={canChangeQuantities}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderLineItems;

OrderLineItems.propTypes = {
  orderId: PropTypes.string.isRequired
};
