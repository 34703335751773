import React from "react";
import { OrderProfileContext } from "./context";

const PackingSlipHeader = () => {
  const { order } = React.useContext(OrderProfileContext);
  return (
    <div className="packing-slip-header">
      <h1>Packing Slip Of Requested Items</h1>
      <h5>{order.invoice_number}</h5>
    </div>
  );
};

export default PackingSlipHeader;
