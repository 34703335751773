import React from 'react';
import { OrderProfileContext } from "../context";

const FollowUpDate = () => {
  const { order, isFetchingOrder, updateOrder } =
    React.useContext(OrderProfileContext);
  return (
    <div>

    </div>
  );
}

FollowUpDate.propTypes = {};

export default FollowUpDate;



