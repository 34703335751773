import React from "react";
import PropTypes from "prop-types";
import { getPendingOrderTasksHoldingOrder } from "features/tasks/helpers";
import { OrderProfileContext } from "components/order-profile-new/context";

const TaskNotification = ({ orderId }) => {
  const { tasks } = React.useContext(OrderProfileContext);
  const orderTasks = getPendingOrderTasksHoldingOrder(tasks, orderId);
  return (
    <div style={{ gridColumn: "1/-1", paddingTop: 10 }}>
      {orderTasks > 0 && (
        <div
          style={{
            color: "#FA9E46",
            fontSize: 14,
            border: "1px solid #ccc",
            padding: 5,
            boxShadow: "0px 3px 5px rgba(0,0,0,0.2)"
          }}
        >
          <div style={{ paddingBottom: 5, fontSize: 18 }}>
            There {orderTasks > 1 ? "are" : "is"}
            <span
              style={{
                color: "#fc8f26",
                fontWeight: "bold",
                paddingRight: 5,
                paddingLeft: 5
              }}
            >
              {orderTasks}
            </span>
            pending task{orderTasks !== 1 ? "s" : ""} requiring attention
          </div>
        </div>
      )}
    </div>
  );
};

TaskNotification.propTypes = { orderId: PropTypes.string.isRequired };

export default TaskNotification;
