import React, { Component } from "react";
import PropTypes from "prop-types";
//import { connect } from "react-redux";
//import { selectors } from "reducers";
import { MdContentCopy } from "react-icons/lib/md";
//import moment from "moment";

const CopyToClipboard = () => {
  const [animate, setAnimate] = React.useState(false);
  return (
    <span
      className={`copy-to-clipboard-button print-none ${
        animate ? "copied-animation" : ""
      }`}
      id="copyToClipboard"
      onClick={() => {
        setAnimate(true);
      }}
      title="Copy To Clipboard"
      onAnimationEnd={() => setAnimate(false)}
    >
      <MdContentCopy size="30px" color="grey" />
      {/* <div */}
      {/*   id={`copyArea-${orderId}`} */}
      {/*   contentEditable="true" */}
      {/*   suppressContentEditableWarning */}
      {/*   style={{ */}
      {/*     position: "fixed", */}
      {/*     left: "-10000px", */}
      {/*     top: "-10000px", */}
      {/*     opacity: 0 */}
      {/*   }} */}
      {/* > */}
      {/*   <h4>{patientName}</h4> */}
      {/*   <pre>Sales Order Number: {orderInfo.sales_order_number}</pre> */}
      {/*   <pre>Account Number: {orderInfo.account_number}</pre> */}
      {/*   <pre>Invoice Number: {orderInfo.invoice_number}</pre> */}
      {/*   <pre>Order Date: {orderInfo.order_date}</pre> */}
      {/*   <pre>Sleep Coach: {orderInfo.sleep_coach_patient_current}</pre> */}
      {/*   <pre>Submitted By: {orderInfo.submitted_by}</pre> */}
      {/*   <pre> */}
      {/*     Quantity Requested: {`${orderInfo.supply_period} day supply`} */}
      {/*   </pre> */}
      {/*   <pre> */}
      {/*     Address:{" "} */}
      {/*     <p> */}
      {/*       {orderInfo.street_address} */}
      {/*       <br /> */}
      {/*       {orderInfo.street_address2} */}
      {/*       <br /> */}
      {/*       {orderInfo.city}, {orderInfo.state}, {orderInfo.zip} */}
      {/*     </p> */}
      {/*   </pre> */}
      {/*   <h4>Physician, Insurance, and Machine Updates</h4> */}
      {/*   {compliance.last_saw_physician && ( */}
      {/*     <pre> */}
      {/*       I last saw my physician regarding sleep therapy{" "} */}
      {/*       {compliance.last_saw_physician} ago. */}
      {/*     </pre> */}
      {/*   )} */}
      {/*   {compliance.physician_changed ? ( */}
      {/*     <pre>I have changed my physician since my last order</pre> */}
      {/*   ) : ( */}
      {/*     <pre> */}
      {/*       No changes have been made to my physician since the last order. */}
      {/*     </pre> */}
      {/*   )} */}
      {/*   <pre> */}
      {/*     Physician: {physicianInfo.name} */}
      {/*     <p> */}
      {/*       {physicianInfo.street_address}, {physicianInfo.city},{" "} */}
      {/*       {physicianInfo.state}, {physicianInfo.zip} */}
      {/*     </p> */}
      {/*   </pre> */}
      {/*   {compliance.issues ? ( */}
      {/*     <pre> */}
      {/*       I am having issues with my PAP device. My current issue is */}
      {/*       regarding {compliance.issue_note} */}
      {/*     </pre> */}
      {/*   ) : ( */}
      {/*     <pre>I am currently having no issues with my PAP therapy.</pre> */}
      {/*   )} */}
      {/*   {compliance.insurance_changed ? ( */}
      {/*     <React.Fragment> */}
      {/*       <pre> */}
      {/*         I have changed insurance companies since my last order. My new */}
      {/*         insurance is: */}
      {/*       </pre> */}
      {/*       <pre>Insurance: {compliance.insurance_company}</pre> */}
      {/*       <pre> Subscriber Id: {compliance.insurance_subscriber_id}</pre> */}
      {/*     </React.Fragment> */}
      {/*   ) : ( */}
      {/*     <pre> */}
      {/*       No changes have been made to my insurance since the last order */}
      {/*     </pre> */}
      {/*   )} */}
      {/*   <h4>Requested Equipment &amp; Dysfunctions</h4> */}
      {/*   {requestedEquipment.map(equipment => ( */}
      {/*     <span key={equipment}> */}
      {/*       <div>{equipment}</div> */}
      {/*       {questions */}
      {/*         .filter(q => q.equipment === equipment) */}
      {/*         .map(({ text }) => ( */}
      {/*           <pre key={text}>{text.trim()}</pre> */}
      {/*         ))} */}
      {/*     </span> */}
      {/*   ))} */}
      {/*   <br /> */}
      {/*   {info.other_issues && info.other_issues.trim() && ( */}
      {/*     <pre>Other Issues: {info.other_issues}</pre> */}
      {/*   )} */}
      {/*   {info.supplies_on_hand && info.supplies_on_hand.trim() && ( */}
      {/*     <pre>Supplies On Hand: {info.supplies_on_hand}</pre> */}
      {/*   )} */}
      {/*   <h5>Equipment Requested</h5> */}
      {/*   {lineItems.map(item => ( */}
      {/*     <React.Fragment key={item.line_item_id}> */}
      {/*       <pre>Type: {item.type}</pre> */}
      {/*       <pre>Id: {item.id}</pre> */}
      {/*       <pre>Manufacturer: {item.manufacturer}</pre> */}
      {/*       <pre>HCPCS: {item.HCPCS}</pre> */}
      {/*       <pre>Product: {item.Product}</pre> */}
      {/*       <br /> */}
      {/*     </React.Fragment> */}
      {/*   ))} */}
      {/*   <h5>Additional Notes</h5> */}
      {/*   {notes.map(({ id, creator, created_at, note }) => ( */}
      {/*     <pre key={id}> */}
      {/*       {creator} {moment(created_at).format("MMMM Do YYYY, h:mm a")} */}
      {/*       <br /> */}
      {/*       {note} */}
      {/*     </pre> */}
      {/*   ))} */}
      {/* </div> */}
    </span>
  );
};

export default CopyToClipboard;

/*
class CopyToClipboard extends Component {
  state = {
    animate: false
  };
  copyToClipboard = () => {
    const copyArea = document.getElementById(`copyArea-${this.props.orderId}`);
    if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(copyArea);
      range.select();
      document.execCommand("Copy");
    } else if (window.getSelection) {
      // other browser support
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(copyArea);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("Copy");
    }
    this.setState({ animate: true });
  };

  render() {
    const {
      orderInfo = {},
      compliance = {},
      info = {},
      requestedEquipment = {},
      questions = {},
      physicianInfo = {},
      lineItems = [],
      notes = [],
      patientName = "",
      orderId
    } = this.props;
    const { animate } = this.state;
    return (
      <span
        className={`copy-to-clipboard-button print-none ${
          animate ? "copied-animation" : ""
        }`}
        id="copyToClipboard"
        onClick={this.copyToClipboard}
        title="Copy To Clipboard"
        onAnimationEnd={() => this.setState({ animate: false })}
      >
        <CopyIcon size="30px" color="grey" />
        <div
          id={`copyArea-${orderId}`}
          contentEditable="true"
          suppressContentEditableWarning
          style={{
            position: "fixed",
            left: "-10000px",
            top: "-10000px",
            opacity: 0
          }}
        >
          <h4>{patientName}</h4>
          <pre>Sales Order Number: {orderInfo.sales_order_number}</pre>
          <pre>Account Number: {orderInfo.account_number}</pre>
          <pre>Invoice Number: {orderInfo.invoice_number}</pre>
          <pre>Order Date: {orderInfo.order_date}</pre>
          <pre>Sleep Coach: {orderInfo.sleep_coach_patient_current}</pre>
          <pre>Submitted By: {orderInfo.submitted_by}</pre>
          <pre>
            Quantity Requested: {`${orderInfo.supply_period} day supply`}
          </pre>
          <pre>
            Address:{" "}
            <p>
              {orderInfo.street_address}
              <br />
              {orderInfo.street_address2}
              <br />
              {orderInfo.city}, {orderInfo.state}, {orderInfo.zip}
            </p>
          </pre>
          <h4>Physician, Insurance, and Machine Updates</h4>
          {compliance.last_saw_physician && (
            <pre>
              I last saw my physician regarding sleep therapy{" "}
              {compliance.last_saw_physician} ago.
            </pre>
          )}
          {compliance.physician_changed ? (
            <pre>I have changed my physician since my last order</pre>
          ) : (
            <pre>
              No changes have been made to my physician since the last order.
            </pre>
          )}
          <pre>
            Physician: {physicianInfo.name}
            <p>
              {physicianInfo.street_address}, {physicianInfo.city},{" "}
              {physicianInfo.state}, {physicianInfo.zip}
            </p>
          </pre>
          {compliance.issues ? (
            <pre>
              I am having issues with my PAP device. My current issue is
              regarding {compliance.issue_note}
            </pre>
          ) : (
            <pre>I am currently having no issues with my PAP therapy.</pre>
          )}
          {compliance.insurance_changed ? (
            <React.Fragment>
              <pre>
                I have changed insurance companies since my last order. My new
                insurance is:
              </pre>
              <pre>Insurance: {compliance.insurance_company}</pre>
              <pre> Subscriber Id: {compliance.insurance_subscriber_id}</pre>
            </React.Fragment>
          ) : (
            <pre>
              No changes have been made to my insurance since the last order
            </pre>
          )}
          <h4>Requested Equipment &amp; Dysfunctions</h4>
          {requestedEquipment.map(equipment => (
            <span key={equipment}>
              <div>{equipment}</div>
              {questions
                .filter(q => q.equipment === equipment)
                .map(({ text }) => (
                  <pre key={text}>{text.trim()}</pre>
                ))}
            </span>
          ))}
          <br />
          {info.other_issues && info.other_issues.trim() && (
            <pre>Other Issues: {info.other_issues}</pre>
          )}
          {info.supplies_on_hand && info.supplies_on_hand.trim() && (
            <pre>Supplies On Hand: {info.supplies_on_hand}</pre>
          )}
          <h5>Equipment Requested</h5>
          {lineItems.map(item => (
            <React.Fragment key={item.line_item_id}>
              <pre>Type: {item.type}</pre>
              <pre>Id: {item.id}</pre>
              <pre>Manufacturer: {item.manufacturer}</pre>
              <pre>HCPCS: {item.HCPCS}</pre>
              <pre>Product: {item.Product}</pre>
              <br />
            </React.Fragment>
          ))}
          <h5>Additional Notes</h5>
          {notes.map(({ id, creator, created_at, note }) => (
            <pre key={id}>
              {creator} {moment(created_at).format("MMMM Do YYYY, h:mm a")}
              <br />
              {note}
            </pre>
          ))}
        </div>
      </span>
    );
  }
}

export default connect((state, props) => {
  const { data: orderInfo } = selectors.getOrderInfo(state, props);
  const { data: adminInfo } = selectors.getOrderAdminInfo(state, props);
  const { data: compliance } = selectors.getOrderComplianceInfo(state, props);
  const {
    data: { info, requestedEquipment, questions }
  } = selectors.getOrderDysfunctionInfo(state, props);
  const { data: lineItems } = selectors.getOrderLineItems(state, props);
  const { data: physicianInfo } = selectors.getOrderPhysicianInfo(state, props);
  const { data: notes } = selectors.getOrderNotes(state, props);
  const { data: patientInfo = {} } = selectors.getOrderPatientInfo(
    state,
    props
  );
  return {
    patientName: patientInfo.patient_name,
    orderInfo,
    compliance,
    info,
    questions,
    lineItems,
    adminInfo,
    requestedEquipment,
    physicianInfo,
    notes
  };
})(CopyToClipboard);

CopyToClipboard.propTypes = {
  orderId: PropTypes.string.isRequired,
  orderInfo: PropTypes.object,
  adminInfo: PropTypes.object,
  compliance: PropTypes.object,
  info: PropTypes.object,
  requestedEquipment: PropTypes.arrayOf(PropTypes.string),
  questions: PropTypes.arrayOf(PropTypes.object),
  physicianInfo: PropTypes.object,
  lineItems: PropTypes.arrayOf(PropTypes.object),
  notes: PropTypes.arrayOf(PropTypes.object),
  patientName: PropTypes.string
};

*/
