import React from "react";
import { NikoHealthLink } from "./niko-health";
import { selectors } from "reducers";
import { useSelector } from "react-redux";
import { OrderProfileContext } from "./context";
import Header from "components/order-profile/header";
import { FieldTable, InlineWaiting, PureModal } from "components/ui";
import { formatPhone } from "utils/misc";
import { formatDate } from "utils/dates";
import ModalLink from "components/shared/modal-link";
import EditSalesOrderModel from "./edit-sales-order-modal";

const OrderInformation = () => {
  const { order, isFetchingOrder, updateOrder, isUpdatingOrder } =
    React.useContext(OrderProfileContext);
  const userId = useSelector(selectors.getUserId);
  const userRole = useSelector(selectors.getUserRole);
  const canClaim = [
    "OfficeAdministrator",
    "CompanyAdministrator",
    "ServiceAdmin",
    "ServiceCoach",
    "ContractorAdministrator"
  ].includes(userRole);
  const canEditSalesOrderNumber =
    [
      "Administrator",
      "OfficeAdministrator",
      "CompanyAdministrator",
      "ServiceAdmin",
      "ServiceCoach",
      "ContractorAdministrator"
    ].includes(userRole) &&
    ["Pending", "On Hold", "Completed"].includes(order.order_status);
  return (
    <div className="order-information">
      {order.uses_niko_api && (
        <NikoHealthLink
          niko_info={order.niko_info}
          account_number={order.account_number}
        />
      )}
      <Header
        showSpinner={isFetchingOrder}
        className="order-information-header"
      >
        Order Information
      </Header>
      <FieldTable
        columnCount={2}
        rows={[
          [
            "Sales Order",
            canEditSalesOrderNumber && !isFetchingOrder ? (
              <PureModal
                renderTrigger={({ openModal }) => (
                  <span
                    key="order-sales-number"
                    className={"edit-sales-order-number"}
                    onClick={() => {
                      console.log("open");
                      openModal();
                    }}
                  >
                    {order.sales_order_number
                      ? order.sales_order_number
                      : "Add"}
                  </span>
                )}
                renderContent={({ closeModal }) => (
                  <EditSalesOrderModel closeModal={closeModal} />
                )}
              />
            ) : (
              <span key="order-sales-number">
                {order.sales_order_number?.trim()}
              </span>
            )
          ],
          [
            "Account Number",
            order?.patientId ? (
              <ModalLink to={`/patients/${order?.patientId}`}>
                {order?.account_number}
              </ModalLink>
            ) : (
              order?.account_number
            )
          ],
          ["Invoice Number", order.invoice_number],
          ["Street Address", order.shipping_address?.street_address],
          ["Street Address Line Two", order.shipping_address?.street_address2],
          ["City", order.shipping_address?.city],
          ["State", order.shipping_address?.state],
          ["ZIP", order.shipping_address?.zip],
          [
            "Order Date",
            formatDate(order.created_date, "MMMM Do YYYY, h:mm a")
          ],
          ["Company", order.company_name],
          [
            "Sleep Coach",
            order.current_patient_coach_name ? (
              <ModalLink to={`/users/${order.current_patient_coach_name}`}>
                {order.current_patient_coach_name}
              </ModalLink>
            ) : (
              order.current_patient_coach_name
            )
          ],
          [
            "Assignment",
            order.sleep_coach_assignment_id ? (
              <ModalLink to={`/users/${order.sleep_coach_assignment_id}`}>
                {order.sleep_coach_assignment_name}
              </ModalLink>
            ) : (
              order.sleep_coach_assignment_name
            )
          ],
          [
            "Admin",
            canClaim && !isFetchingOrder && order.claimed_by_id !== userId ? (
              <>
                {isUpdatingOrder ? (
                  <InlineWaiting />
                ) : (
                  <span
                    onClick={() => {
                      updateOrder({ claimed_by: userId });
                    }}
                    className="admin-claim"
                  >
                    {(order.claimed_by_name && order.claimed_by_name.trim()) ||
                      "Claim"}
                  </span>
                )}
              </>
            ) : (
              order.claimed_by_name
            )
          ],
          ["Phone Number", formatPhone(order.patient_phone_number)],
          [
            "Email",
            <a
              key={`${userId}-${order.patient_email}`}
              href={`mailto:${order.patient_email}`}
            >
              {order.patient_email}
            </a>
          ],
          ["Insurance", order.primary_insurance_name],
          ["Submitted By", order.submitted_by_name],
          [
            "Quantity Requested",
            order.supply_period ? `${order.supply_period} day supply` : ""
          ]
        ].map(([field, value]) => ({ field, value }))}
      />
    </div>
  );
};

OrderInformation.propTypes = {};

export default OrderInformation;
