import React from "react";
import { OrderProfileContext } from "../context";
import DatePicker from "components/ui/date-picker";
import { formatDate } from "utils/dates";

const DelayedPendingDate = () => {
  const { order, updateOrder } = React.useContext(OrderProfileContext);
  const hasPendingIvrWork = order.status == "IVR Pending Work";
  return (
    <div className="pending-delayed-container no-print">
      <span className="subheader-text">Delayed Date:</span>
      <span className="pending-delayed-field">
        <DatePicker
          css={{
            display: "inline-block",
            float: "right"
          }}
          modifiers={{
            disabled: { before: new Date() }
          }}
          containerCss={{ left: -200, bottom: -10 }}
          disabled={!!order.fulfillment_type || hasPendingIvrWork}
          onChange={day => {
            // if order has fulfillment_type can only clear date
            if (hasPendingIvrWork) return;
            if (!day) {
              updateOrder({
                delayed_pending_date: "",
                delayed_pending: false
              });
            } else {
              updateOrder({
                delayed_pending_date: formatDate(day, "YYYY-MM-DD"),
                delayed_pending: true
              });
            }
          }}
        />
        <span
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {formatDate(order.delayed_pending_date, "ll")}
        </span>
      </span>
    </div>
  );
};

export default DelayedPendingDate;
