import React from "react";
import PropTypes from "prop-types";
import Header from "components/order-profile/header";
import { InlineWaiting } from "components/ui";
import { OrderProfileContext } from "./context";
import { get, post, del } from "utils/api";
import { useFetch } from "hooks";
import AddInventoryItemByIdForm from "./add-inventory-item-by-id-form.jsx";
import AddInventoryItemForm from "./add-inventory-item-form.jsx";

const AddOrderLineItems = ({ orderId }) => {
  const isSubmitting = false;

  const { order, fetchOrderLineItemsApi } =
    React.useContext(OrderProfileContext);

  // const orderLineItems = fetchOrderLineItemsApi.response;

  const addOrderLineItemByIdApi = useFetch({
    apiFn: ({ product_id, addQuantity }) =>
      post(`orders/${orderId}/add_line_item_by_id`, {
        product_id,
        addQuantity
      }),
    onSuccess: () => fetchOrderLineItemsApi.fetchData(orderId)
  });
  const addOrderLineItemApi = useFetch({
    apiFn: ({ id, addQuantity }) =>
      post(`orders/${orderId}/add_line_item`, {
        id,
        addQuantity
      }),
    onSuccess: () => fetchOrderLineItemsApi.fetchData(orderId)
  });

  const companyInventoryItemsApi = useFetch({
    apiFn: ({ patient_id, product_line }) =>
      get(
        `patients/${patient_id}/company_inventory?product_line=${product_line}`
      ),
    defaultValue: [],
    transformResponse: x => x.inventory
  });

  React.useEffect(() => {
    if (order.patient_id) companyInventoryItemsApi.fetchData(order);
  }, [order.patient_id]);

  const companyInventoryItems = companyInventoryItemsApi.response;

  return (
    <div className="no-print">
      <Header showSpinner={isSubmitting}>Add New Line Item</Header>
      {!!order.patient_id && (
        <div className="add-order-equipment-container">
          {companyInventoryItemsApi.isFetching ? (
            <div
              style={{
                height: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <InlineWaiting />
            </div>
          ) : (
            <>
              <AddInventoryItemByIdForm
                showQuantity={true}
                addItemByID={addOrderLineItemByIdApi.fetchData}
              />
              <AddInventoryItemForm
                showQuantity={true}
                inventory_items={companyInventoryItems}
                addInventoryItem={addOrderLineItemApi.fetchData}
              />
            </>
          )}
          {/*<AddEquipmentForm
                      withQuantity
                      handleSubmit={({ id, addQuantity }) => {
                        console.log("requestNewLineItem(", id, addQuantity);
                      }}
                      isSubmitting={isSubmitting}
                      initialFilter={{ compatibleEquipment: true }}
                      line_items={orderLineItems}
                      patientId={order.patient_id}
                    />*/}
        </div>
      )}
    </div>
  );
};

export default AddOrderLineItems;
AddOrderLineItems.propTypes = {
  orderId: PropTypes.string.isRequired
};
