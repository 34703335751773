import React from 'react';
import { OrderProfileContext } from "../context";

const ReasonRejected = () => {
  const { order, isFetchingOrder, updateOrder } =
    React.useContext(OrderProfileContext);
  return (
    <div>

    </div>
  );
}

ReasonRejected.propTypes = {};

export default ReasonRejected;

