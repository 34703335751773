import React from "react";
import PropTypes from "prop-types";
import { OrderProfileContext } from "components/order-profile-new/context";
import { InlineWaiting } from "components/ui";
import TaskItem from "./task-item";
import OrderNote from "./order-note";
import AddClipboardItem from "./add-clipboard-item";
import * as R from "ramda";

const OrderClipboard = ({ orderId }) => {
  const { fetchOrderNotesApi, tasks, order, isFetchingTasks } =
    React.useContext(OrderProfileContext);

  const notes = fetchOrderNotesApi?.response ?? [];

  const orderTasks = tasks.filter(x => x.order_id == orderId);
  const [selectedClipboardOption, setSelectedClipboardOption] =
    React.useState("tasks");

  const clipboardItems = R.sortWith(
    [R.descend(R.prop("created_at"))],
    [
      ...(selectedClipboardOption !== "tasks" ? notes : []),
      ...(selectedClipboardOption !== "notes" ? orderTasks : [])
    ]
  );
  return (
    <div className="order-tasks-panel">
      <ul className="task-sidebar scrollbar-dark">
        <li
          className={`task-sidebar-item ${
            selectedClipboardOption === "all" ? "active" : ""
          }`}
          onClick={() => {
            setSelectedClipboardOption("all");
          }}
        >
          All
        </li>
        <li
          className={`task-sidebar-item ${
            selectedClipboardOption === "tasks" ? "active" : ""
          }`}
          onClick={() => {
            setSelectedClipboardOption("tasks");
          }}
        >
          <div className="number-step">{orderTasks?.length}</div>
          Tasks
        </li>
        <li
          className={`task-sidebar-item ${
            selectedClipboardOption === "notes" ? "active" : ""
          }`}
          onClick={() => {
            setSelectedClipboardOption("notes");
          }}
        >
          <div className="number-step">{notes?.length}</div>
          Notes
        </li>
      </ul>
      {isFetchingTasks ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            padding: 30
          }}
        >
          <InlineWaiting reason="Fetching Patient Tasks" />
        </div>
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateRows: "1fr auto",
            height: "inherit"
          }}
        >
          <div className="s3-ui-rows task-list">
            {clipboardItems.map(x =>
              Object.prototype.hasOwnProperty.call(x, "note") ? (
                <OrderNote {...x} key={x.id} />
              ) : (
                <TaskItem
                  task={x}
                  orderId={orderId}
                  patientId={order.patient_id}
                  key={x.task_id}
                />
              )
            )}
          </div>
          <div
            style={{
              display: "flex",
              borderTop: "1px solid #ccc"
            }}
          >
            <AddClipboardItem
              orderId={orderId}
              patientId={order.patient_id}
              orderTaskIds={orderTasks.map(x => x.task_id)}
              canAddTask
            />
          </div>
        </div>
      )}
    </div>
  );
};

OrderClipboard.propTypes = {
  orderId: PropTypes.string.isRequired,
  patientId: PropTypes.string,
  notes: PropTypes.arrayOf(PropTypes.object)
};

export default React.memo(OrderClipboard);
