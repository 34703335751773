import isEmail from "validator/lib/isEmail";
import moment from "moment";
import * as api from "utils/api";

export const isRequired = value => {
  if (typeof value == "number" || typeof value == "boolean") return undefined;
  return value ? undefined : "Required.";
};

export const normalizePhone = value => {
  if (!value) return value;
  const international = value.startsWith("+");
  return international
    ? "+" + value.replace(/\D/g, "")
    : value.replace(/\D/g, "");
};

export const formatPhone = value => {
  if (!value) {
    return value;
  }
  const phoneDigits = value.replace(/[^[0-9]]/g, "");
  if (phoneDigits.length <= 3) {
    return phoneDigits;
  } else if (phoneDigits.length <= 7) {
    return `${phoneDigits.slice(0, 3)}-${phoneDigits.slice(3)}`;
  } else if (phoneDigits.length <= 10) {
    return `${phoneDigits.slice(0, 3)}-${phoneDigits.slice(3, 6)}-${phoneDigits.slice(6)}`;
  }
  return value;
};

export const isValidDate = value =>
  !value || moment(value, "L", true).isValid()
    ? undefined
    : "Invalid Date, expected format dd/mm/yyyy.";

export const isValidPhone = value =>
  value && !/^(\+?)(0|[0-9][0-9]{9,13})$/i.test(value)
    ? "Invalid phone number."
    : undefined;

export const isValidZip = value =>
  value && !/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(value)
    ? "Invalid Zip Code."
    : undefined;

export const isValidEmail = (value, isRequired = true) =>
  isRequired || value
    ? value && !isEmail(value.trim())
      ? "Invalid Email."
      : undefined
    : undefined;

export const asyncValidateUniqueEmail = async (values, _, props = {}) => {
  try {
    const { email } = values;
    const { patientInfo } = props;
    if (typeof email === "string" && email.length > 0) {
      const { ids = [] } = await api.post(`patients/check_duplicate_email`, {
        email,
        user_id: patientInfo.id
      });
      if (patientInfo) {
        if (ids.length > 0 && !ids.includes(patientInfo.id))
          throw Error("Email is already used.");
      } else {
        if (ids.length > 0) throw Error("Email is already used.");
      }
    }
  } catch (error) {
    if (error.message === "Email is already used.")
      throw { email: error.message };
  }
};

export const containsNoCreditCardInfo = value => {
  const americanExpress = "\\b(?:3[47][0-9]{13})\\b";
  const dinersClub = "\\b(?:3(?:0[0-5]|[68][0-9])[0-9]{11})\\b";
  const discover = "\\b(?:6(?:011|5[0-9]{2})(?:[0-9]{12}))\\b";
  const jcb = "\\b(?:(?:2131|1800|35\\d{3})\\d{11})\\b";
  const maestro = "\\b(?:(?:5[0678]\\d\\d|6304|6390|67\\d\\d)\\d{8,15})\\b";
  const mastercard =
    "\\b(?:(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12})\\b";
  const visa = "\\b(?:4[0-9]{12})(?:[0-9]{3})?\\b";

  const generate = pattern => new RegExp(pattern, "g");

  const ccReg = generate(
    [
      americanExpress,
      dinersClub,
      discover,
      jcb,
      maestro,
      mastercard,
      visa
    ].join("|")
  );
  if (typeof value === "string" && ccReg.test(value.replace(/-/g, ""))) {
    return "Not Allowed potentially sensitive information.";
  }
};

export const validateNpiNumber = value =>
  value && !/^[0-9]{10}$/i.test(`${value}`)
    ? "NPI number must be 10 digits"
    : undefined;
