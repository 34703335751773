import React from "react";
import PropTypes from "prop-types";
import { OrderProfileContext } from "../context";
import debounce from "lodash/debounce";

const PendingInfo = ({ disabled }) => {
  const { order, updateOrder } = React.useContext(OrderProfileContext);

  const [value, setValue] = React.useState(order.pending_info);

  React.useEffect(() => {
    setValue(order.pending_info);
  }, [order.pending_info]);

  function handleOnBlur({ target: { value } }) {
    if (!value.trim() && !order.pending_info) return;
    else if (value.trim() !== order.pending_info) {
      requestUpdateOrderPendingInfo(value);
    }
  }

  const requestUpdateOrderPendingInfo = debounce(
    newValue => updateOrder({ pending_info: newValue }),
    300,
    { leading: false }
  );
  return (
    <input
      value={value || ""}
      onChange={({ target }) => setValue(target.value)}
      onBlur={handleOnBlur}
      title={value || "No Pending Info Recorded"}
      disabled={disabled}
    />
  );
};

PendingInfo.propTypes = {
  disabled: PropTypes.bool,
  pending_info: PropTypes.string
};

export default PendingInfo;
