import React from "react";
import PropTypes from "prop-types";
import { useFormikContext, FieldArray } from "formik";
import { formatDate } from "utils/dates";
import supplyOptions from "./supply-options";
import HasRolePermission from "components/shared/has-role-permission";
import NoMaskReasonInput from "components/order-form/no-mask-reason-input";

const ChooseSupplies = ({
  patientHCPCHistory,
  equipmentOffered,
  eligibleSupplies
}) => {
  const { values, errors, setFieldValue } = useFormikContext();

  var selectedAllEligibleSupplies = eligibleSupplies.every(
    x =>
      values.requested_equipment.includes(x) &&
      eligibleSupplies.length == values.requested_equipment.length
  );

  return (
    <div>
      <FieldArray
        name="requested_equipment"
        render={selectedEquipment => (
          <>
            <div className="choose-order-supplies">
              <div className="eligbleDisclaimerParent" style={{ margin: 10 }}>
                <div className="eligbleDisclaimer">
                  * Items automatically selected are based off perceived
                  eligibility from insurance rules
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center"
                    }}
                  >
                    <button
                      style={{ margin: 10, padding: 5, maxWidth: 200 }}
                      disabled={selectedAllEligibleSupplies}
                      type="button"
                      onClick={() => {
                        setFieldValue("requested_equipment", eligibleSupplies);
                      }}
                    >
                      Select Eligible Items
                    </button>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <ul>
                  {supplyOptions
                    .filter(x => equipmentOffered?.includes(x.value))
                    .map(({ value, text, description }) => {
                      const thisItem = patientHCPCHistory?.find(phh =>
                        phh.productType
                          .toLowerCase()
                          ?.includes(value.toLowerCase())
                      );
                      return (
                        <li key={value}>
                          <label>
                            <div className="supply-checkbox-container">
                              <input
                                name="requested_equipment"
                                type="checkbox"
                                value={value}
                                checked={values.requested_equipment.includes(
                                  value
                                )}
                                onChange={e => {
                                  if (thisItem == null || thisItem.isEligible) {
                                    if (e.target.checked)
                                      selectedEquipment.push(value);
                                    else {
                                      const idx =
                                        values.requested_equipment.indexOf(
                                          value
                                        );
                                      selectedEquipment.remove(idx);
                                    }
                                  } else {
                                    if (!thisItem.isEligible) {
                                      if (e.target.checked) {
                                        if (
                                          confirm(
                                            "You are selecting an item that the patient may not be eligible for, would you like to continue?"
                                          )
                                        ) {
                                          selectedEquipment.push(value);
                                        } else {
                                          return;
                                        }
                                      } else {
                                        const idx =
                                          values.requested_equipment.indexOf(
                                            value
                                          );
                                        selectedEquipment.remove(idx);
                                      }
                                    }
                                  }
                                }}
                              />
                            </div>
                            <div>
                              <div style={{ display: "flex" }}>
                                <div>
                                  {text}
                                  {"   "}
                                </div>{" "}
                                {thisItem && (
                                  <div
                                    className={
                                      thisItem.isEligible
                                        ? "eligible"
                                        : "notEligible"
                                    }
                                  >
                                    {thisItem.isEligible
                                      ? "Eligible"
                                      : formatDate(
                                          thisItem.eligibilityDate,
                                          "MM/DD/YYYY"
                                        )}
                                  </div>
                                )}
                              </div>
                              <div className="description">{description}</div>
                            </div>
                          </label>
                        </li>
                      );
                    })}
                </ul>
              </div>
              <HasRolePermission allowedRoles={["SleepCoach"]}>
                <NoMaskReasonInput
                  requested_equipment={values.requested_equipment}
                />
              </HasRolePermission>
            </div>
          </>
        )}
      />
      {errors.requested_equipment && (
        <div
          className="order-form-warning"
          style={{
            padding: "10px",
            border: "1px solid #ff3333",
            color: "#ff3333",
            background: "#ffcccc33",
            fontWeight: "600",
            margin: "5px 0"
          }}
        >
          {errors.requested_equipment}
        </div>
      )}
    </div>
  );
};

ChooseSupplies.defaultProps = {};

ChooseSupplies.propTypes = {
  patientHCPCHistory: PropTypes.arrayOf(PropTypes.object),
  equipmentOffered: PropTypes.arrayOf(PropTypes.string),
  patientInfo: PropTypes.object.isRequired,
  eligibleSupplies: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ChooseSupplies;
