import React from "react";
import PropTypes from "prop-types";
import Header from "components/order-profile/header";
import { OrderProfileContext } from "./context";

const ShippingDetails = () => {
  const { order, isFetchingOrder } = React.useContext(OrderProfileContext);

  return (
    <div>
      <Header showSpinner={isFetchingOrder}>
        <span>Shipping</span>
        {order.fulfillment_type && (
          <span style={{ float: "right" }} className="no-print">
            Fullfillment Type: {order.fulfillment_type}
          </span>
        )}
      </Header>
      <div className="package-shipping">
        <ShippingCard
          header="Ship To:"
          name={order.patient_name}
          {...order.shipping_address}
        />
        <ShippingCard
          header="Ship From:"
          name={order.company_name}
          {...order.company_address}
        />
      </div>
    </div>
  );
};

export default ShippingDetails;

function ShippingCard({
  header,
  name,
  street_address,
  street_address2,
  city,
  state,
  zip
}) {
  return (
    <div className="package-shipping-card">
      <div>{header}</div>
      <div>
        <h6>{name}</h6>
        <div>{street_address}</div>
        <div>{street_address2}</div>
        <div>
          {city}
          {city && state && ", "}
          {state} {zip}
        </div>
      </div>
    </div>
  );
}
ShippingCard.propTypes = {
  header: PropTypes.string,
  name: PropTypes.string,
  street_address: PropTypes.string,
  street_address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string
};
