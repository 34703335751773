import React from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import FormikField from "components/forms/formik-field";
import RadioGroupField from "components/forms/radio-group-field";
import { questionValidation } from "./validation";

const FormElement = ({ id, type, options, text, disabled }) => {
  const { values } = useFormikContext();
  switch (type) {
    case "Yes/No":
      return (
        <Field
          component={RadioGroupField}
          name={`questions.${id}`}
          label={text}
          value={values[id]}
          disabled={disabled}
          options={[
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]}
          validate={questionValidation(id)}
        />
      );
    case "Multiple Choice":
      return (
        <>
          <FormikField
            name={`questions.${id}`}
            component="select"
            label={text}
            disabled={disabled}
            validate={questionValidation(id)}
          >
            <option />
            {options.map(({ id, text, value }) => (
              <option key={id} value={value}>
                {text}
              </option>
            ))}
          </FormikField>
          {(() => {
            const child = options.find(
              x => x.value === values.questions[id]
            )?.question;
            if (child != null) return <FormElement {...child} />;
          })()}
        </>
      );
    case "Text":
      return (
        <FormikField
          name={`questions.${id}`}
          type="text"
          label={text}
          disabled={disabled}
          validate={questionValidation(id)}
        />
      );
    case "Date":
      return (
        <FormikField
          name={`questions.${id}`}
          type="date"
          label={text}
          disabled={disabled}
          validate={questionValidation(id)}
        />
      );
    case "Disclaimer":
      return (
        <div
          className="order-form-warning"
          style={{
            padding: "10px",
            border: "1px solid #ff3333",
            color: "#ff3333",
            background: "#ffcccc33",
            fontWeight: "600",
            margin: "5px 0"
          }}
        >
          {text}
        </div>
      );
    default:
      return JSON.stringify({ type, options, text });
  }
};

FormElement.propTypes = {
  type: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ])
    })
  ),
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default FormElement;
