import React from "react";
import PropTypes from "prop-types";
import { errorMessage, message } from "actions/message";
import { useDispatch, useSelector } from "react-redux";
import { get, put } from "utils/api";
import { useFetch } from "hooks";
import { selectors } from "reducers";
import { InlineWaiting, PageHeader, Waiting } from "components/ui/";
import { Formik, Form } from "formik";
import FormikFieldLeftAligned from "components/forms/formik-field-left-aligned";

const CompanyOrderForms = ({ companyId }) => {
  const dispatch = useDispatch();

  const company = useSelector(selectors.getCompanyProfile);
  const fetchCompanyOrderFormsAPI = useFetch({
    apiFn: productLine =>
      get(`companies/${companyId}/order-form/${productLine}`),
    defaultValue: [],
    clearDataOnFetch: false,
    onError: error =>
      dispatch(
        errorMessage(`Failed to get comany order form: ${error.message}`)
      )
  });

  const updateCompanyOrderFormAPI = useFetch({
    apiFn: ({ orderForm, productLine }) =>
      put(`companies/${companyId}/order-form/${productLine}`, orderForm),
    defaultValue: {},
    onSuccess: () => dispatch(message("Updated company order form ")),
    onError: error =>
      dispatch(
        errorMessage(`Failed to update company order form: ${error.message}`)
      )
  });
  const [selectedProductLine, setSelectedProductLine] = React.useState(
    company?.product_lines?.[0] ?? ""
  );
  React.useEffect(() => {
    if (selectedProductLine)
      fetchCompanyOrderFormsAPI.fetchData(selectedProductLine);
  }, [selectedProductLine]);
  React.useEffect(() => {
    setSelectedProductLine(company?.product_lines?.[0] ?? "");
  }, [company]);
  return (
    <div
      style={{ padding: 10, display: "flex", flexDirection: "column", flex: 1 }}
    >
      <PageHeader title="Order Forms">
        <div>
          <label> Product Line</label>
          <select
            value={selectedProductLine}
            onChange={e => setSelectedProductLine(e.target.value)}
          >
            {company.product_lines?.map(x => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </select>
        </div>
      </PageHeader>

      <div>
        {fetchCompanyOrderFormsAPI.isFetching ? (
          <div
            style={{
              display: "flex",
              minHeight: 400,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <InlineWaiting />
          </div>
        ) : (
          <Formik
            initialValues={[...fetchCompanyOrderFormsAPI.response]}
            onSubmit={async values => {
              await updateCompanyOrderFormAPI.fetchData({
                orderForm: values,
                productLine: selectedProductLine
              });
              await fetchCompanyOrderFormsAPI.fetchData(selectedProductLine);
            }}
            enableReinitialize={true}
          >
            {({ values, isSubmitting }) => (
              <Form style={{ padding: 10 }}>
                {fetchCompanyOrderFormsAPI.response.map((x, index) => (
                  <div
                    key={x.id}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto 1fr",
                      marginBottom: 10,
                      borderBottom: "1px solid #555",
                      gridGap: 20
                    }}
                  >
                    <FormikFieldLeftAligned
                      name={`${index}.active`}
                      label=""
                      type="checkbox"
                      checkbox={values?.[index]?.active}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div>
                        <b>Identifier:</b> <span>{x.id}</span>
                      </div>
                      <div>
                        <b>Question Type:</b> <span>{x.type}</span>
                      </div>
                      <div>
                        <b>Category:</b> <span>{x.category}</span>
                      </div>
                      <div>
                        <span>{x.text}</span>
                      </div>
                    </div>
                  </div>
                ))}
                {isSubmitting ? (
                  <InlineWaiting />
                ) : (
                  <button type="submit">Save</button>
                )}
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

CompanyOrderForms.propTypes = { companyId: PropTypes.string.isRequired };

export default CompanyOrderForms;
