import React from "react";
import isBoolean from "lodash/isBoolean";
import { OrderProfileContext } from "./context";

const ReplacementOrder = () => {
  const { order, updateOrder, isUpdatingOrder } =
    React.useContext(OrderProfileContext);

  const disabled = isUpdatingOrder || order.incorrect_shipment === undefined;
  return (
    <div className="order-profile-replacement-order">
      {isBoolean(order.incorrect_shipment) && (
        <label>
          <input
            type="checkbox"
            checked={order.incorrect_shipment}
            disabled={disabled}
            onChange={
              async ({ target: { checked } }) =>
                await updateOrder({
                  incorrect_shipment: checked
                })
            }
          />
          <span>This is a replacement order.</span>
        </label>
      )}
    </div>
  );
};

export default ReplacementOrder;
