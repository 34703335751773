import React from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import RadioGroupField from "components/forms/radio-group-field";
import FormikField from "components/forms/formik-field";
import { STATES } from "utils/enum";
import { isRequired, isValidZip } from "utils/redux-form";
import FormElement from "./form-elements";

const AddressForm = ({ patientInfo, questions }) => {
  const { values, setFieldValue, errors } = useFormikContext();

  React.useEffect(() => {
    if (values.questions.address_change === false) {
      setFieldValue("address", patientInfo.address);
    }
  }, [values.questions.address_change]);

  React.useEffect(() => {
    if (errors.address) {
      setFieldValue("questions.address_change", true);
    }
  }, [errors.address]);

  const address_change_question = questions.find(
    q => q.id === "address_change"
  );
  if (!address_change_question) {
    return null;
  }

  const disabled = !values.questions.address_change;

  console.log(errors);

  return (
    <div>
      <div className="order-form-prompt">
        {address_change_question && (
          <Field
            component={RadioGroupField}
            name="questions.address_change"
            label="My address has changed or what we have on file is incorrect."
            validate={x => (typeof x === "boolean" ? undefined : "Required")}
            value={values.questions.address_change}
            options={[
              { value: true, text: "Yes" },
              { value: false, text: "No" }
            ]}
          />
        )}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: 10
        }}
      >
        <FormikField
          label="Street Address"
          name="address.street_address"
          type="text"
          validate={isRequired}
          disabled={disabled}
        />
        <FormikField
          label="Street Address Line Two"
          name="address.street_address2"
          type="text"
          disabled={disabled}
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridGap: 10
        }}
      >
        <FormikField
          label="City"
          name="address.city"
          type="text"
          validate={isRequired}
          disabled={disabled}
        />
        <FormikField
          label="State"
          name="address.state"
          component="select"
          validate={isRequired}
          disabled={disabled}
        >
          <option />
          {STATES.map(({ value, text }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </FormikField>
        <FormikField
          label="Zip"
          name="address.zip"
          type="text"
          validate={v => isRequired(v) || isValidZip(v)}
          disabled={disabled}
        />
      </div>
      <div>
        {values.questions.address_change && (
          <>
            {errors.address_change_type && (
              <div style={{ color: "red" }}>{errors.address_change_type}</div>
            )}
            <Field
              component={RadioGroupField}
              name="address_change_type"
              label=""
              value={values.address_change_type}
              validate={x => (x ? undefined : "Required")}
              options={[
                { value: "P", text: "Permanent Address Change" },
                { value: "T", text: "Temporary Address Change" }
              ]}
            />
          </>
        )}
      </div>
      <hr />
      <div>
        {questions
          ?.filter(x => x.id != "address_change")
          ?.map(x => (
            <FormElement key={x.id} {...x} validate={isRequired} />
          ))}
      </div>
    </div>
  );
};

export default AddressForm;

AddressForm.propTypes = {
  values: PropTypes.shape({
    address: PropTypes.object,
    changed_address: PropTypes.bool,
    address_change_type: PropTypes.string,
    ordered_by: PropTypes.string
  }),
  patientInfo: PropTypes.object.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired
};
