import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import { MdSend, MdUpdate } from "react-icons/lib/md";
// import { initiateSendOrderToNikoHealth } from "actions/order-profile";
// import { initiateRetrieveOrderFromNikoHealth } from "actions/order-profile";

export const NikoHealthLink = ({ niko_info, account_number }) => {
  return (
    <div style={{ fontSize: "medium", fontWeight: "bold" }}>
      <a
        href={
          niko_info?.niko_order_id != ""
            ? `${niko_info?.niko_url}v2/#/orders/order/${niko_info?.niko_order_id}/details`
            : `${niko_info?.niko_url}v2/#/patients/${account_number}/demographics`
        }
        target="_blank"
        rel="noreferrer"
      >
        {niko_info?.niko_order_id != ""
          ? "Niko Health Order Details"
          : "Niko Health User Profile"}
      </a>
    </div>
  );
};

NikoHealthLink.propTypes = {
  niko_info: PropTypes.object,
  account_number: PropTypes.string
};

export const SendOrderToNHButton = ({ orderId }) => {
  const [disabled, setDisabled] = useState(false);
  const userId = useSelector(selectors.getUserId);

  return (
    <span
      className={`copy-to-clipboard-button print-none`}
      title={
        disabled ? "Sending order to Niko Health" : "Send order to Niko Health"
      }
    >
      <MdSend
        color="grey"
        size="28px"
        disabled={disabled}
        onClick={() => {
          if (disabled) {
            return;
          }
          if (userId !== "" && orderId !== "") {
            setDisabled(true);
            /*
            initiateSendOrderToNikoHealth(userId, orderId).then(() => {
              setDisabled(false);
            });
            */
          } else {
            window.alert("Please wait for order to finish loading.");
          }
        }}
      />
    </span>
  );
};

SendOrderToNHButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  initiateSendOrderToNikoHealth: PropTypes.func.isRequired
};

export const UpdateOrderFromNHButton = ({
  orderId
  //initiateRetrieveOrderFromNikoHealth
}) => {
  const [disabled, setDisabled] = useState(false);
  const userId = useSelector(selectors.getUserId);

  return (
    <span
      className={`copy-to-clipboard-button print-none`}
      title={
        disabled
          ? "Updating order from Niko Health"
          : "Update order from Niko Health"
      }
    >
      <MdUpdate
        color="grey"
        size="28px"
        disabled={disabled}
        onClick={() => {
          if (disabled) {
            return;
          }
          if (userId !== "" && orderId !== "") {
            setDisabled(true);
            /*
            initiateRetrieveOrderFromNikoHealth(userId, orderId).then(() => {
              setDisabled(false);
            });
            */
          } else {
            window.alert("Please wait for order to finish loading.");
          }
        }}
      />
    </span>
  );
};

UpdateOrderFromNHButton.propTypes = {
  orderId: PropTypes.string.isRequired
};
